/* GENERAL STYLING */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
}

html {
    scroll-behavior: smooth;
}

p {
    color: rgb(85, 85, 85);
}

/* TRANSITION */

a, 
.btn {
    transition: all 300ms ease;
}

a {
    color: black;
    text-decoration: none;
    text-decoration-color: white;
}

a:hover {
    color: grey;
    text-decoration: underline;
    text-underline-offset: 1rem;
    text-decoration-color: rgb(181, 181, 181);
}

/* PROFILE */
.visitor-counter {
    display: block;
    margin-top: 20px;
}

/* DESKTOP NAV */

nav, .nav-links {
    display: flex;
}

.nav-links {
    gap: 2rem;
    list-style: none;
    font: 1.5rem;
}

nav {
    justify-content: space-around;
    align-items: center;
    height: 17vh;
}

.logo {
    font-size: 2rem;
}

.logo:hover {
    cursor: default;
}

/* HAMBURGER MENU */

#hamburger-nav {
    display: none;
}

.hamburger-menu {
    position: relative;
    display: inline-block;
}

.hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 24px;
    width: 30px;
    cursor: pointer;
}

.hamburger-icon span {
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 0.3 ease-in-out;
}

.menu-links {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    width: fit-content;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3 ease-in-out;
}

.menu-links a {
    display: block;
    padding: 10px;
    text-align: center;
    font-size: 1.5rem;
    color:black;
    text-decoration: none;
    transition: all 0.3 ease-in-out;
}

.menu-links li {
    list-style: none;
}

.menu-links.open {
    max-height: 300px;
}

.hamburger-icon.open span:first-child {
    transform: rotate(45deg) translate(10px, 5px);
}

.hamburger-icon.open span:nth-child(2) {
    opacity: 0;
}

.hamburger-icon.open span:last-child {
    transform: rotate(-45deg) translate(10px, -5px);
}

.hamburger-icon span:first-child {
    transform: none;
}

.hamburger-icon span:first-child {
    opacity: 1;
}

.hamburger-icon span:first-child {
    transform: none;
}

/* SECTIONS */

section {
    padding-top: 4vh;
    height: 96vh;
    margin:0 10rem;
    box-sizing: border-box;
    min-height: fit-content;
}

.section-container {
    display: flex;
}

/*PROFILE SECTION */

#profile {
    display: flex;
    justify-content: center;
    gap: 5rem;
    height: 80vh;
}

.section__pic-container {
    display: flex;
    height: 400px;
    width: 400px;
    margin: auto 0;
}

.profile-pic-container img {
    width: 100%; /* Ensure the image fills the container */
    height: 100%; /* Ensure the image fills the container */
    object-fit: cover; /* This will cover the container with the image without stretching it */
    border-radius: 50%; /* This makes the image round */
}



.section__text {
    align-self: center;
    text-align: center;
}

.section__text p {
    font-weight: 600;
}

.section__text__p1 {
    text-align: center;
}

.section__text__p2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
}

.title {
    font-size: 3rem;
    text-align: center;
}

#socials-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 1rem;
}

/* ICONS */

.icon {
    cursor: pointer;
    height: 2rem;
}

/* BUTTONS */

.btn-container {
    display: flex;
    justify-content: center;
}

.btn {
    font-weight: 600;
    transition: all 300ms ease;
    padding: 1rem;
    width: 8rem;
    border-radius: 2rem;
}

.btn-color-1, .btn-color-2 {
    border: rgb(53, 53, 53) 0.1rem solid;
}

.btn-color-1:hover,
.btn-color-2:hover {
    cursor: pointer;
}

.btn-color-1,
.btn-color-2:hover {
    background: rgb(53, 53, 53);
    color: white;
}

.btn-color-1:hover {
    background: rgb(0, 0, 0);
}

.btn-color-2 {
    background: none;
}

.btn-color-2:hover {
    border: rgb(255, 255, 255) 0.1rem solid;
}

.btn-container {
    gap: 1rem;
}

/* ABOUT SECTION */

#about {
    position: relative;
}

.non-clickable-icon {
    cursor: default;
    height: 2rem;
}

.about-containers {
    gap: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    display: block;
}

.about-details-container {
    justify-content: center;
    flex-direction: column;
}

.about-containers, .about-details-container {
    display: flex;
}

.about-pic {
    border-radius: 2rem;
}

.details-container li {
    list-style-position: inside;
    font-size: small;
    padding-top: 0.5rem;
}

.education-text {
    padding-top: 0.3rem;
}

.school {
    font-size:medium;
    font-weight: 900;
    color: black;
}

.degree {
    display: inline-flex;
    padding-top: 1.25rem;
    font-size: small;
    font-style: oblique;
}

.experience-list-item {
    display:block;
}

.experience-list-item p {
    font-style: oblique;
    font-size: smaller;
}

.projects-arrow {
    position: absolute;
    right: -5rem;
    bottom: 4.5rem;
}

.about-arrow {
    position: absolute;
    right: -5rem;
    bottom: 15rem;
}
.experience-arrow {
    position: absolute;
    right: -5rem;
    bottom: 1.5rem;
}

.details-container {
    padding: 1.5rem;
    flex: 1;
    background: white;
    border-radius: 2rem;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: rgb(163, 163, 163);
    text-align: center;
    /* max-width: calc(50% - 10px); */
}

.centered-container {
    width: 100%; /* Take the full width */
    margin: 1rem auto !important; /* Add some vertical margin and center horizontally */
    display: block !important; /* Override flex display */
    text-align: center; 
}

.section-container {
    gap: 4rem;
    height: 80%;
}

.section__pic-container {
    height: 400px;
    width: 400px;
    margin: auto 0;
}

/* EXPERIENCE SECTION */

#experience {
    position: relative;
    height: fit-content;
    width: fit-content;
}

.experience-sub-title {
    color: rgb(85, 85, 85);
    font-weight: 600;
    font-size: 1.75rem;
    margin-bottom: 2rem;
}

.experience-details-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.article-container {
    display: flex;
    text-align: initial;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2.5rem;
    justify-content: space-around;
}

article {
    display: flex;
    width: 10rem;
    justify-content: space-around;
    gap: 0.5rem;
}

article .icon {
    cursor: default;
}

/* PROJECTS SECTION */

#projects {
    position: relative;
    height:fit-content;
}

.color-container {
    margin-inline: 1rem;
    border-color: rgb (163, 163, 163);
    background: rgb(250, 250, 250);
}

.centered-projects-container {
    width: 100%; /* Take the full width */
    margin-top: 1rem;
    margin-bottom: 4rem;
    text-align: center; /* Center the content */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust the min and max values as necessary */
    height: 100%;
}

.imaged-projects {
    height: 100%; /* Full height */
    display: flex;
    flex-direction: column;
}

.project-img {
    border-radius: 2rem;
    width: 100%;
    height: auto;
    max-height: 25rem; /* Adjust this value based on your preference */
    object-fit: cover; /* Adjusts the image's display to cover the area, can be changed to 'contain' if you want to see the whole image */
}

.project-title {
    margin-top: 1rem;
    color: black;
    font-weight: 600;
    font-size: 1.75rem;
}

.project-role {
    color: rgb(85, 85, 85);
    font-weight: 400;
    font-size: 1.25rem;
    margin-bottom: 0.86rem;
}

.project-description {
    margin-top: 1rem;
    color: black;
    font-weight: 200;
    font-size: 1rem;
    flex-grow: 1; /* Allows the description to fill available space, pushing the button container to the bottom */
}

.smaller-description {
    margin-top: 1rem;
    color: black;
    font-weight: 200;
    font-size: 1rem;
    flex-grow: 1; /* Allows the description to fill available space, pushing the button container to the bottom */
    margin-bottom: 1rem;
}

.project-btn {
    color: black;
    border-color: rgb (163, 163, 163);
}

.text-only-proj {
    display: flex;
    flex-direction: column;
}

/* CONTACT */

#contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 70vh;
}

.contact-info-upper-container {
    display: flex;
    justify-content: center;
    border-radius: 2rem;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: rgb(163, 163, 163);
    background: rgb(250, 250, 250);
    margin: 2rem auto;
    padding: 0.5rem;
}

.contact-info-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem;
}

.contact-info-container p {
    font-size: larger;
}

.contact-icon {
    cursor: default;
}

.email-icon {
    height: 2.5rem;
}

/* FOOTER SECTION */

footer {
    height: 26vh;
    margin: 0 1rem;
}

footer p {
    text-align: center;
}